export enum FieldCRM {
  dynamicsLeadId = "dynamicsLeadId",
  quoteDate = "quoteDate",
  province = "state",
  offeredPremium = "offeredPremium",
  offeredPlanName = "offeredPlanName",
  country = "ECUADOR",
  isoCurrencyCode = "isoCurrencyCode",
  isoCurrencyCodeValue = "USD",
  genderMale = "Male",
  genderFemale = "Female",
  genderOther = "Other",
  male = "Masculino",
  female = "Femenino",
  other = "Unknown",
  policySystemCode = "policySystemCode",
  policySystemCodeValue = "S6",
  purchasedDate = "purchasedDate",
  policyEffectiveDate = "policyEffectiveDate",
  policyNumber = "policyNumber",
  destinationName = "additionalData/destinationName",
  departureDate = "additionalData/departureDate",
  arrivalDate = "additionalData/arrivalDate",
  destinationType = "additionalData/destinationType",
  numberAdditionalTravelers = "additionalData/numberAdditionalTravelers",
  salesChannel = "salesChannel",
  salesChannelValue = "ECOMMERCE",
  privacyPolicyAcceptedDate = "additionalData/PrivacyPolicyAcceptedDate",
  statusField = "status",
  won = "won",
  purchased = "Purchased",
  abandoned = "Abandoned",
  new = "New",
  timeWait = "30000",
  lastPathThankYou = "seguro-activo"
}