import {
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { CampaignService } from "./campaign.service";
import { GoogleAnalyticServiceService } from "@helpers/google-analytic-service.service";
import { Injectable } from "@angular/core";
import { MaxtriesContentModalComponent } from "@app/components/maxtries-content-modal/maxtries-content-modal.component";
import { ModalComponent } from "@app/components/modal/modal.component";
import { ModalContentIsCustomerComponent } from "@app/components/modal-content-is-customer/modal-content-is-customer.component";
import { PayfailComponent } from "@app/components/payfail/payfail.component";
import { ResponseTitleComponent } from "@app/components/response-title/response-title.component";
import { Router } from "@angular/router";
import { TitleModalConfig } from "@shared/title-modal-config";

@Injectable({
  providedIn: "root",
})
export class ErrorListService {
  activeDialog: MatDialogRef<ModalComponent>;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private googleAnalyticService: GoogleAnalyticServiceService,
    public campaignService: CampaignService
  ) {}

  public openModalError(data = null, errorObj = null) {
    var data = data ? data : this.getErrorByCode("000.5001");
    /*data = data.errorCode.startsWith("006")
      ? this.getErrorByCode("000.5001")
      : data;*/
    var configTitle: TitleModalConfig = data.configTitle;
    if (configTitle.data instanceof Function) {
      configTitle.data = configTitle.data();
    }
    if (errorObj?.data?.Title) {
      configTitle.emphasisTitle = errorObj?.data?.Title;
    }

    var saleType = this.campaignService.getSaleStatus()?.prefixUrl;

    if (
      saleType ==
        (CampaignService.PREFIX_SALE.F2F || CampaignService.PREFIX_SALE.CC) &&
      data?.confirmHTMLF2F
    ) {
      data.confirmHTML = data.confirmHTMLF2F;
    }
    var configContent =
      errorObj?.data?.Body ||
      data.component ||
      data.confirmHTML ||
      data.confirmText ||
      data.errorData;
    var dynContent =
      typeof configContent == "string"
        ? configContent.startsWith("<")
          ? {
              confirmHTML: configContent,
            }
          : {
              confirmText: configContent,
            }
        : {
            component: configContent,
          };
    const dialogRef = this.dialog.open(ModalComponent, {
      id: `dialog-error-${data.errorCode || "default"}`,
      backdropClass: "backdrop-blur",
      panelClass: "panel-mobile",
      minHeight: "80vh",
      maxHeight: "100vh",
      data: {
        configTitle,
        titleComponent: ResponseTitleComponent,
        ...dynContent,
        confirmActions: data.confirmActions,
      },
    });
    this.activeDialog = dialogRef;
    if (data?.errorAnalytics) {
      this.googleAnalyticService.googleTaskManagerPush({
        event: "system_error",
        error_type: data.errorAnalytics,
        flow_type: this.campaignService.getSaleStatus()?.prefixUrl,
        errorData: data.errorData,
      });
    }
    dialogRef.componentInstance.dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        data.confirmFn();
      } else {
        data.cancelFn ? data.cancelFn() : data.confirmFn();
      }
    });
    return dialogRef;
  }
  public gotoPaymentConfirmed = (data, url = "/confirmacion-pago") => {
    this.router.navigate([url, { state: { data: data } }]);
  };
  public goTo404 = () => {
    //this.router.navigate(["/not-found"]);
    this.redirectToHome();
  };
  public goToNotFound = () => {
    this.router.navigate(["/not-found"]);
  };
  public goToHome = () => {
    this.router.navigate(["/home"]);
  };
  public redirectToHome = () => {
    this.router.navigate(["/home"]);
  };
  public goToSelectPlan = () => {
    this.router.navigate(["/select-plan"]);
  };
  public checkErrorToSuccess(error) {
    const errorsToSuccess = [
      "005.500",
      "005.9998",
      "015.500",
      "015.9998",
      "004.default",
      "101",
    ];
    return errorsToSuccess.includes(error.errorCode);
  }
  public checkCustomerInsured(error) {
    const errorsCustomerInsured = ["411", "412", "413"];
    return errorsCustomerInsured.includes(error.errorCode);
  }
  configActions = [
    {
      fromConfig: ["thanks", "sorry"],
      confirmActions: {
        confirmLabel: "Aceptar",
      },
      confirmFn: () => "cannotContinue",
    },
    {
      fromConfig: ["expiredCode"],
      confirmActions: {
        confirmLabel: "Enviar Nuevo Código",
      },
      confirmFn: () => "expiredCode",
    },
    {
      fromConfig: ["maxTries"],
      confirmActions: {
        confirmLabel: "Enviar Nuevo Código",
      },
      confirmFn: () => "maxTries",
    },
    {
      fromConfig: ["payFail", "otpFail"],
      confirmActions: {
        confirmLabel: "Aceptar",
      },
      confirmFn: () => "cannotContinue",
    },
    {
      fromConfig: ["expiredSession"],
      confirmActions: {
        confirmLabel: "Reintentar",
      },
      confirmFn: this.goToSelectPlan,
    },
    {
      fromConfig: ["requote"],
      confirmActions: {
        confirmLabel: "Aceptar recotización",
        cancelLabel: "Cancelar",
      },
    },
    {
      fromConfig: ["imeiFail"],
      confirmActions: {
        confirmLabel: "Reintentar",
      },
    },
  ];
  configsList = [
    {
      id: "thanks",
      fromCode: ["000.1004", "000.1005"],
      configTitle: {
        bgclass: "success",
        icon: "info_outline",
        emphasisTitle:
          "Gracias por preferir a Chubb para proteger lo que es más valioso para ti.",
      },
    },
    {
      id: "sorry",
      fromCode: ["000.5001", "004.9999", "000.401", "010.9999", "010.101"],
      configTitle: {
        bgclass: "fail",
        icon: "sentiment_very_dissatisfied",
        emphasisTitle: "¡Lo sentimos mucho!",
      },
    },
    {
      id: "maxTries",
      fromCode: ["008.0007"],
      configTitle: {
        bgclass: "fail",
        icon: "sentiment_very_dissatisfied",
        emphasisTitle: "¡Haz alcanzado tu máximo número de intentos!",
      },
    },
    {
      id: "expiredCode",
      fromCode: ["008.0001", "008.0005"],
      configTitle: {
        bgclass: "fail",
        icon: "sentiment_very_dissatisfied",
        emphasisTitle: "¡Tu código ha expirado!",
      },
    },
    {
      id: "expiredSession",
      fromCode: ["000.401"],
      configTitle: {
        bgclass: "fail",
        icon: "sentiment_very_dissatisfied",
        emphasisTitle: "¡Lo sentimos mucho!",
      },
    },
    {
      id: "rangeAgeClient",
      fromCode: ["rangeAgeClient"],
      configTitle: {
        bgclass: "fail",
        icon: "sentiment_very_dissatisfied",
        emphasisTitle: "¡Lo sentimos mucho!",
      },
    },
  ];

  markupCallUsString = `<div class="d-flex flex-column justify-content-center">
  <p class="message">
    Si quieres puedes continuar tu contratación con asistencia telefónica
  </p>
  <div class="d-flex flex-column flex-lg-row justify-content-around mb-3">
    <a mat-button class="mat-button mat-button-base mat-primary call-us-btn mt-3" color="primary" href="tel:02-395-7731"> <span class="call-icon"></span> Llámanos</a>
    <p class="message call-us mt-3 d-flex flex-column-reverse"><a href="/agenda-una-llamada">Agendar una llamada</a></p>
  </div>
</div>
`;
  errorsList = [
    {
      errorCode: "000.1004",
      errorData:
        "La oferta ya no puede ser usada, por favor contacte a su asesor.",
      errorAnalytics: "offer_used",
      configTitle: this.getConfigErrorById("thanks").configTitle,
      confirmText: "Notamos que al momento ya cuentas con esta cobertura.",
      confirmActions: this.getActionsByConfigId("thanks").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "013.9998",
      errorData: "No se ha podido obtener informacion de la oferta",
      errorAnalytics: "no_data_offer",
      configTitle: this.getConfigErrorById("thanks").configTitle,
      confirmText: "No se ha podido obtener informacion de la oferta.",
      confirmActions: this.getActionsByConfigId("thanks").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "000.2004",
      errorData:
        "La oferta ya no puede ser usada, por favor contacte a su asesor.",
      errorAnalytics: "offer_cant_be_used",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>Por ahora no podemos procesar tu contratación en línea, actualiza tus datos en la Banca Móvil Banco Guayaquil</p> ${this.markupCallUsString}`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.getActionsByConfigId("sorry").confirmFn,
    },
    {
      errorCode: "000.4003",
      errorData: "No existen formas de pago disponibles",
      errorAnalytics: "contact_call_center",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>¡No hemos podido encontrar un método de pago!<br/>Para revisar si se encuentran activos comunícate con Banco Guayaquil al <a href='tel:043730100'>043730100</a>, o intenta nuevamente mas tarde</p><img alt='Usuario usando smartphone' class=\"scroll-container__img\" style='display:block;margin:0 auto;max-width:200px;width:100%' src='/assets/img/home/cellphone-modal.svg' />`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "000.5001",
      errorData: "La oferta no esta en el estado correcto.",
      errorAnalytics: "offer_not_correct",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.getActionsByConfigId("sorry").confirmFn,
    },
    {
      errorCode: "009.1003",
      errorData: "Error generando otp.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "000.403",
      errorData: "Error generando otp.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "003.404",
      errorData: "No existen datos del cliente.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "007.0001",
      errorData: "El cliente no existe.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "007.1",
      errorData: "El cliente no existe.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "013.1002",
      errorData: "Error en campaña.",
      errorAnalytics: "error_campaign",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "013.1003",
      errorData: "Error en campaña.",
      errorAnalytics: "error_campaign",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "004.9999",
      errorData: "No se encontro el producto requerido.",
      errorAnalytics: "product_not_found",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.getActionsByConfigId("sorry").confirmFn,
    },
    {
      errorCode: "008.0001",
      errorData:
        "Su código de seguridad ha expirado, se enviará uno nuevo automáticamente.",
      errorAnalytics: "code_expired",
      configTitle: this.getConfigErrorById("expiredCode").configTitle,
      confirmText: `Lo sentimos, tu código de seguridad ha expirado.
No te preocupes, enviaremos un nuevo código a tu teléfono celular y correo electrónico para que puedas disfrutar de este beneficio.`,
      confirmActions: this.getActionsByConfigId("expiredCode").confirmActions,
      confirmFn: this.getActionsByConfigId("expiredCode").confirmFn,
    },
    {
      errorCode: "008.0005",
      errorData:
        "Su código de seguridad ha expirado, se enviará uno nuevo automáticamente.",
      errorAnalytics: "code_expired",
      configTitle: this.getConfigErrorById("expiredCode").configTitle,
      confirmText: `Lo sentimos, tu código de seguridad ha expirado.
No te preocupes, enviaremos un nuevo código a tu teléfono celular y correo electrónico para que puedas disfrutar de este beneficio.`,
      confirmActions: this.getActionsByConfigId("expiredCode").confirmActions,
      confirmFn: this.getActionsByConfigId("expiredCode").confirmFn,
    },
    {
      errorCode: "008.0006",
      errorData:
        "El código de seguridad que ha ingresado no es el correcto, por favor ingrese el correcto o vuelva a intentar el inicio de sesión.",
      fn: () => "updateValidation",
    },
    {
      errorCode: "008.0007",
      errorData:
        "Su código de seguridad ha superado el número máximo de intentos, vuelva a intentar el inicio de sesión.",
      errorAnalytics: "max_try_reached",
      configTitle: this.getConfigErrorById("maxTries").configTitle,
      component: MaxtriesContentModalComponent,
    },
    {
      errorCode: "010.9999",
      errorData: "Metodos de pago no cubren el valor de la prima.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.getActionsByConfigId("sorry").confirmFn,
    },
    {
      errorCode: "010.101",
      errorData: "No tiene método de pago disponible.",
      errorAnalytics: "error_no_data",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.getActionsByConfigId("sorry").confirmFn,
    },
    {
      errorCode: "014.500",
      errorData: "Error interno, debito no procesada.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions:
        this.getActionsByConfigId("expiredSession").confirmActions,
    },
    {
      errorCode: "014.7777",
      errorData: "Error al ejecutar el debito.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions:
        this.getActionsByConfigId("expiredSession").confirmActions,
    },
    {
      errorCode: "payFail",
      errorData: "Error al utilizar todos los métodos de pago.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("payFail").confirmActions,
      confirmFn: this.getActionsByConfigId("payFail").confirmFn,
    },
    {
      errorCode: "payFailQuiet",
      errorData: "Error al utilizar todos los métodos de pago.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("payFail").confirmActions,
      confirmFn: () => "payError",
    },
    {
      errorCode: "000.4009",
      errorData: "Error al realizar pago.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: PayfailComponent,
      confirmActions: {
        confirmLabel: "Reintentar",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "000.1005",
      errorData: "Campaña ha expirado.",
      errorAnlytics: "campaing_expired",
      configTitle: this.getConfigErrorById("thanks").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("thanks").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "000.401",
      errorData: "Sesión expirada.",
      errorAnalytics: "session_expired",
      configTitle: this.getConfigErrorById("expiredSession").configTitle,
      confirmHTML: `<p>
  Por tu seguridad, la sesión ha expirado. Por favor inténtalo nuevamente.
  <br />
  Por ahora no podemos procesar tu contratación en línea.
</p> ${this.markupCallUsString}`,
      confirmActions:
        this.getActionsByConfigId("expiredSession").confirmActions,
      confirmFn: this.getActionsByConfigId("expiredSession").confirmFn,
    },
    {
      errorCode: "4002",
      errorData: "Error procesando la transacción.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "4003",
      errorData: "Respuesta no exitosa de una API externa.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "4004",
      errorData: "No se encuentra el registro.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `No hemos encontrado ningun dispositivo asociado al IMEI que ingresaste.

Intenta reingresar nuevamente el IMEI de tu celular.`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "4005",
      errorData: "Dispositivo no encontrado o no asegurable.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `No hemos encontrado ningun dispositivo asociado al IMEI que ingresaste.

Intenta reingresar nuevamente el IMEI de tu celular.`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "4006",
      errorData: "Client ID inválido o no encontrado en la lista de clientes.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `No hemos encontrado ningun dispositivo asociado al IMEI que ingresaste.

Intenta reingresar nuevamente el IMEI de tu celular.`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "4007",
      errorData:
        "El ID de la cotización es inválido, se debe proveer ID de cotización interno o quoteNumber válido.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `No hemos encontrado no pudimos obtener una cotización para tu celular en este momento.

      Por favor inténtalo nuevamente en unos minutos.`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "ageNoPermit",
      errorData: "Uno de los viajeros tiene mas de 80 años",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>Notamos que uno de los viajeros es un adulto mayor a 80 años y no es posible continuar con la compra de este seguro por este canal.
  <br/>
  Te recomendamos continuar con el proceso sin este viajero. Si lo prefieres comunícate con nosotros al <a href="tel:1700-111-999">1700-111-999</a> / <a href="tel:02-395-7731">02-395-7731</a>
<p>`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
    },
    {
      errorCode: "rangeAgeNoPermit",
      errorData: "El rango de edad no es permitido",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>En este momento no podemos procesar tu transacción.
  <br/>
  Si lo prefieres comunícate con nosotros al <a href="tel:1700-111-999">1700-111-999</a> / <a href="tel:02-395-7731">02-395-7731</a> para encontrar la mejor cobertura para ti.
</p>`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "rangeAgeClient",
      errorData: "Rango de edad inconsistente",
      configTitle: this.getConfigErrorById("rangeAgeClient").configTitle,
      confirmText: `Se procederá a recotizar el plan debido a que el rango de edad seleccionado anteriormente no corresponde a la fecha de nacimiento.`,
      confirmActions: this.getActionsByConfigId("requote").confirmActions,
    },
    {
      errorCode: "410",
      configTitle: this.getConfigErrorById("thanks").configTitle,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "411",
      configTitle: this.getConfigErrorById("thanks").configTitle,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "412",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: this.redirectToHome,
    },
    {
      errorCode: "IMEIFail",
      errorData: "Error en cotizar por IMEI",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `No hemos encontrado una cotización para tu celular en este momento.

      Por favor Inténtalo nuevamente en unos minutos.`,
      confirmActions: this.getActionsByConfigId("imeiFail").confirmActions,
    },
    {
      errorCode: "errorPets",
      errorData: "Error al capturar datos de la mascota.",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      component: ModalContentIsCustomerComponent,
      confirmActions: this.getActionsByConfigId("payFail").confirmActions,
      confirmFn: this.getActionsByConfigId("payFail").confirmFn,
    },
    {
      errorCode: "006.1",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar su solicitud.
      <br />
      Por favor  comuniquese con nuestro Call Center para más información <a href="tel:1700-111-999">1700-111-999</a> / <a href="tel:02-395-7731">02-395-7731</a>
</p>`,
      confirmHTMLF2F: `<p>Lamentamos no poder continuar con el proceso de contratación, el cliente no cumple políticas.
      <br />
      Por favor contacta al personal de Cumplimiento BG para que el cliente sea aclarado y, de ser el caso, puedas continuar con el proceso.
</p>`,
      confirmActions: this.getActionsByConfigId("otpFail").confirmActions,
      confirmFn: this.goToHome,
    },
    {
      errorCode: "006.2",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>
      Su código de seguridad no es el correcto, por favor ingreselo nuevamente.
</p>`,
      confirmActions: this.getActionsByConfigId("otpFail").confirmActions,
      confirmFn: () => true,
    },
    {
      errorCode: "006.3",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar su solicitud.
      <br />
      Por favor, generar un nuevo código de seguridad y vuelva a intentar.
</p>`,
      confirmActions: this.getActionsByConfigId("otpFail").confirmActions,
      confirmFn: () => true,
    },
    {
      errorCode: "006.293",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar su solicitud.
      <br />
      Por favor  comuniquese con nuestro Call Center para más información <a href="tel:1700-111-999">1700-111-999</a> / <a href="tel:02-395-7731">02-395-7731</a>
</p>`,
      confirmHTMLF2F: `<p>
      Lamentamos no poder continuar con el proceso de contratación, el cliente no cumple políticas.
      <br />
      Por favor contacta al personal de Cumplimiento BG para que el cliente sea aclarado y, de ser el caso, puedas continuar con el proceso.
</p>`,
      confirmActions: this.getActionsByConfigId("otpFail").confirmActions,
      confirmFn: () => this.goToHome,
    },
    {
      errorCode: "006.294",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("expiredSession").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar su solicitud.
      <br />
      Por favor comuniquese con nuestro Call Center para más información <a href="tel:1700-111-999">1700-111-999</a> / <a href="tel:02-395-7731">02-395-7731</a>
</p>`,
      confirmHTMLF2F: `<p>
      En este momento no podemos procesar su solicitud.
      <br />
      Indicar el inconveniente del cliente
      </p>`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: () => this.goToHome,
    },
    {
      errorCode: "006.500",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("expiredSession").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar tu transacción.
      <br />
      Porfavor vuelva a genera un nuevo código de seguridad.
</p>`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: () => true,
    },
    {
      errorCode: "006.9999",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("expiredSession").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar tu transacción.
      <br />
      Porfavor vuelva a genera un nuevo código de seguridad.
</p>`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: () => true,
    },
    {
      errorCode: "006.default",
      errorData: "OFAC Error",
      errorAnalytics: "ofac_error",
      configTitle: this.getConfigErrorById("expiredSession").configTitle,
      confirmHTML: `<p>
      En este momento no podemos procesar tu transacción.
      <br />
      Porfavor vuelva a genera un nuevo código de seguridad.
</p>`,
      confirmActions: this.getActionsByConfigId("sorry").confirmActions,
      confirmFn: () => true,
    },
    {
      errorCode: "009.1001",
      errorData: "Cliente no tiene correo asignado",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Lo sentimos mucho, no hemos podido enviar la notificación por correo, por favor actualiza el correo de tu cliente a través del caso CRM-Actualización datos de contacto y correspondencia, y vuelve a intentar dentro de 5 minutos`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "009.400",
      errorData: "Fallo en el servicio de envío de cotización al correo",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Lo sentimos mucho, tenemos problemas al enviar la cotización por correo, por favor contáctate con el CAU e ingresa un ticket de atención o llama al 86000`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "000.1020",
      errorData: "Fallo en el servicio de envío de cotización al correo",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Lo sentimos mucho, tenemos problemas al enviar la cotización, por favor contáctate con el CAU e ingresa un ticket de atención o llama al 86000`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "029.500",
      errorData: "Fallo el servicio para obtener la firma del vendedor",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Lo sentimos mucho, tenemos problemas al consultar el servicio, por favor contáctate con el CAU e ingresa un ticket de atención o llama al 86000`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "009.404",
      errorData:
        "No se pudo establecer conexión con el servicio de notificaciones o existe un error interno de conexión al servidor",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Lo sentimos mucho, tenemos problemas de conexión, por favor contáctate con el CAU e ingresa un ticket de atención o llama al 86000`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "009.500",
      errorData:
        "No se pudo establecer conexión con el servicio de notificaciones o existe un error interno de conexión al servidor",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Lo sentimos mucho, tenemos problemas de conexión, por favor contáctate con el CAU e ingresa un ticket de atención o llama al 8600`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "000.1025",
      errorData:
        "El cliente, ya tiene una cotizacion vigente por favor revise y vuelva a intentar.",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText:
        "El cliente, ya tiene una cotizacion vigente por favor revise y vuelva a intentar.",
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "000.1030",
      errorData:
        "No se pudo establecer conexión con el servicio de notificaciones o existe un error interno de conexión al servidor",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Se produjo un error cuando se intentaba enviar al servicio de auditoria de cotizacion.`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "005.1030",
      errorData:
        "No se pudo establecer conexión con el servicio de notificaciones o existe un error interno de conexión al servidor",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Se produjo un error cuando se intentaba enviar al servicio de auditoria de cotizacion.`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "F2FBGEmail",
      errorData: "BG Email F2F Error",
      errorAnalytics: "bg_email_error",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      //confirmText: `Lo sentimos mucho, el correo no ha podido ser enviado, existe un error con el servicio. Por favor, vuelva a intentarlo más tarde`,
      confirmActions: {
        confirmLabel: "Entendido",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "notOwnCar",
      errorData: "No es auto propio o no es de uso particular.",
      errorAnalytics: "not_own_car",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      confirmText: `Chubb en este momento no se encuentra cotizando el vehículo que deseas. En un futuro esperamos poder ofrecerte un producto que se ajuste a tus necesidades.

No queremos que te vayas desprotegido!, tenemos otros productos que podrían interesarte. Presiona "Aceptar" y te mostraremos nuestos seguros.`,
      confirmActions: {
        confirmLabel: "Aceptar",
      },
      confirmFn: () => true,
    },
    {
      errorCode: "plateNotValid",
      errorData: "Placa ingresada no es asegurable.",
      errorAnalytics: "plate-not_valid",
      configTitle: this.getConfigErrorById("sorry").configTitle,
      /*confirmText: `Chubb en este momento no se encuentra cotizando el vehículo que deseas. En un futuro esperamos poder ofrecerte un producto que se ajuste a tus necesidades.

No queremos que te vayas desprotegido!, tenemos otros productos que podrían interesarte. Presiona "Aceptar" y te mostraremos nuestos seguros.`,*/
      confirmActions: {
        confirmLabel: "Corregir placa",
        cancelLabel: "Explorar otros seguros",
      },
      confirmFn: () => true,
      cancelFn: () => this.goToHome(),
    },
  ];

  public parentEvent(msg) {
    this.activeDialog.componentInstance.parentEvent.emit({ message: msg });
  }
  public getErrorByCode(input) {
    return this.errorsList.find((err) => err.errorCode == input);
  }

  public getConfigErrorById(input) {
    return this.configsList.find((err) => err.id == input);
  }

  public getConfigErrorByCode(input) {
    return this.configsList.find((err) => err.fromCode.includes(input));
  }

  public getActionsByConfigId(input) {
    return this.configActions.find((err) => err.fromConfig.includes(input));
  }

  public IMEIOverride(inputHTML) {
    return inputHTML;
  }
}
